<template>
  <div>
    <div class="d-flex justify-content-between">
      <h1 class="text-center mb-4"><b>{{ plan.billingTitle || plan.title }}</b></h1>
    </div>
    <div class="mb-5">
      <h3 class="mb-2">Billing period</h3>
      <div>
        <b-form-group class="billing-period-form">
          <b-form-radio v-if="plan.monthlyPrice"
                        v-model="billingPeriod" name="billing-period"
                        :value="plansTypes.MONTHLY" class="mb-2">
            <div class="d-flex justify-content-between align-items-center w-100">
              <h4 class="font-600 mb-0">{{ plansTypes.MONTHLY | firstLetterUpper }}</h4>
              <h2 class="mb-0 d-flex align-items-center">${{ plan.monthlyPrice }}<span class="pre-md-text">
                &sol;month &nbsp;&nbsp;{{ renderSeatsOnTeamAccount }}</span></h2>
            </div>
          </b-form-radio>
          <b-form-radio v-if="plan.annualPrice" v-model="billingPeriod" name="billing-period"
                        :value="plansTypes.ANNUAL">
            <div class="d-flex justify-content-between align-items-center w-100">
              <h4 class="font-600 mb-0">{{ plansTypes.ANNUAL | firstLetterUpper }}</h4>
              <h2 class="mb-0 d-flex align-items-center">${{ plan.annualPrice * 12 }}<span class="pre-md-text">
                &sol;year &nbsp;&nbsp;{{ renderSeatsOnTeamAccount }}</span></h2>
            </div>
            <span class="d-flex justify-content-end align-items-center pr-3">(${{plan.annualPrice}}&sol;month)</span>
          </b-form-radio>
        </b-form-group>
      </div>
    </div>
    <template v-if="plan.accountType === accountTypes.TEAM">
      <div class="d-flex justify-content-between align-items-center mb-4 users-seats-block">
        <p class="font-600">Number of seats</p>
        <div class="d-flex align-items-center justify-content-center btns">
          <base-button type="secondary" size="sm" @click="seats > 3 && seats--">-</base-button>
          <p class="mb-0 text-center font-600 text-lg">{{ seats }}</p>
          <base-button type="secondary" size="sm" @click="seats++">+</base-button>
        </div>
      </div>
    </template>
<!--    <template v-if="!isPayg">-->
<!--      <ValidationObserver v-slot="{ invalid, validate }" ref="formValidator">-->
<!--        <form @submit.prevent="validate().then(applyCode)">-->
<!--          <div class="d-flex align-items-start mb-4 coupon-code-block">-->
<!--            <div class="flex-fill">-->
<!--              <base-input-->
<!--                v-model="couponCode"-->
<!--                label="Promo code"-->
<!--                :showErrors="false"-->
<!--                :showErrorBorder="false"-->
<!--                :successMessage=promoCodeSuccessMessage-->
<!--              />-->
<!--            </div>-->
<!--            <base-button type="primary" class="ml-3 apply-button" :disabled="invalid" nativeType="submit"-->
<!--                        :loading="promoCodeLoad">Apply-->
<!--            </base-button>-->
<!--          </div>-->
<!--        </form>-->
<!--      </ValidationObserver>-->
<!--    </template>-->
    <div class="d-flex justify-content-between align-items-start mb-4">
      <div class="pr-3">
        <h2 class="mb-0">Total</h2>
        <p><i>{{ autoRenewText }}</i></p>
        <p v-if="this.appliedCode !== ''"><i>{{ promoCodeSuccessInfoText }}</i></p>
      </div>

      <template v-if="isPayg">
        <div class="d-flex flex-column">
          <h1 class="xl-text font-800">${{plan.monthlyPrice}}</h1>
          <span class="font-600 xs-text">100 free texts!</span>
        </div>
      </template>

      <template v-else-if="plan.accountType === accountTypes.PERSONAL">
        <h1 class="xl-text font-800 total-price" v-bind:class="{ 'text-through': (appliedCode !== '') }">
          ${{ (billingPeriod === plansTypes.MONTHLY) ? plan.monthlyPrice : (plan.annualPrice * 12) }}
          <span class="font-600 lg-text">&sol;{{ (billingPeriod === plansTypes.MONTHLY) ? 'Month' : 'Year' }}</span>
        </h1>
      </template>

      <template v-else-if="plan.accountType === accountTypes.TEAM">
        <h1 class="xl-text font-800 total-price" v-bind:class="{ 'text-through': (appliedCode !== '') }">
          ${{ (billingPeriod === plansTypes.MONTHLY) ? (plan.monthlyPrice * seats) : (plan.annualPrice * 12 * seats) }}
          <span class="font-600 lg-text">&sol;{{ (billingPeriod === plansTypes.MONTHLY) ? 'Month' : 'Year' }}</span>
        </h1>
      </template>

      <template v-else>
        <h1 class="xl-text font-800" v-bind:class="{ 'text-through': (appliedCode !== '') }">${{ plan.monthlyPrice }}
          <span class="font-600 lg-text">&sol;Month</span>
        </h1>
      </template>
    </div>
    <h3>Payment</h3>
    <FadeTransition>
      <div v-if="plan.accountType === accountTypes.TEAM && billingPeriod === plansTypes.ANNUAL">
        <p class="primary-color active-element mb-3 font-600"
           @click="showCard = !showCard">
          <u>{{ showCard ? 'Switch to invoicing' : 'Switch to credit card' }}</u>
        </p>
      </div>
    </FadeTransition>
    <stripe-card
      v-show="showCard"
      ref="creditCard"
      :disabled="isCurrentPlan"
      @on-purchase="confirmPurchase"
    />
    <invoice-email v-show="!showCard"
                   v-model="invoicedEmail"
                   :disabled="isCurrentPlan"
                   disabledText="This is your current plan"
                   @request-invoice="confirmPurchase"
    />
  </div>
</template>
<script>
import {FadeTransition} from 'vue2-transitions';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {accountTypes, paymentModes, plansTypes, userTypes} from '@/store/userConstants';
import BaseInput from '@/components/Inputs/BaseInput.vue';
import InvoiceEmail from './InvoiceEmail.vue';
import {
  PURCHASE_PLAN_REQUEST,
} from '@/store/storeActions';
import StripeCard from './StripeCard.vue';
import {LOADING_STATE_SET} from '@/store/storeActions';
import {getPromoCodeApi} from '@/api/promoCodeApi';
import moment from 'moment';

export default {
  components: {
    BaseInput,
    InvoiceEmail,
    StripeCard,
    FadeTransition,
  },
  props: [
    'plan',
  ],
  data() {
    return {
      billingPeriod: '',
      accountTypes,
      plansTypes,
      seats: 3,
      couponCode: '',
      showCard: true,
      invoicedEmail: '',
      paymentMode: paymentModes.PREPAID,
      appliedCode: '',
      renewDate: null,
      promoCodeLoad: false,
      promoCodeSuccessMessage: '',
    };
  },
  computed: {
    ...mapGetters([
      'accountType',
      'planType',
      'userType',
    ]),
    renderSeatsOnTeamAccount: function() {
      let text = '';
      if (this.plan.accountType === accountTypes.TEAM) {
        text = ` x ${this.seats} seats`;
      }
      return text;
    },
    isCurrentPlan: function() {
      if (this.plan.accountType === this.accountType) {
        return this.billingPeriod === this.planType;
      } else {
        return this.plan.accountType === plansTypes.PAYG && this.plan.planType === this.planType && this.accountType ===
          accountTypes.PERSONAL;
      }
    },
    isPayg() {
      return this.plan ? this.plan.planType === plansTypes.PAYG : false;
    },
    autoRenewText() {
      const date = moment(String(this.renewDate)).format('MMMM DD, YYYY');
      return !this.isPayg ? `Your subscription will auto-renew on ${date}.` :
        `Your subscription will auto-renew on ${date}.
      Additional text packages can be purchased through the Account Settings page.`;
    },
    promoCodeSuccessInfoText() {
      return 'You will be charged at your regular price after promotional period ends.';
    },
  },
  watch: {
    billingPeriod: {
      handler: function(value) {
        const resDate = new Date();
        if (value === plansTypes.MONTHLY) {
          resDate.setMonth(resDate.getMonth() + 1);
          this.showCard = true;
        } else {
          resDate.setFullYear(resDate.getFullYear() + 1);
        }
        this.renewDate = resDate;
        this.resetCode();
      },
      immediate: true,
    },
  },
  mounted() {
    const periods = [plansTypes.MONTHLY, plansTypes.ANNUAL];
    this.billingPeriod = periods.includes(this.plan.selectedPlanType) ? this.plan.selectedPlanType : plansTypes.MONTHLY;
  },
  methods: {
    ...mapActions([
      PURCHASE_PLAN_REQUEST,
    ]),
    ...mapMutations([
      LOADING_STATE_SET,
    ]),
    changeFieldHandler: function({field, value}) {
      this[field] = value;
    },
    applyCode: async function() {
      if (!!this.couponCode) {
        this.promoCodeLoad = true;
        try {
          const promoCodeResponse = await getPromoCodeApi(
            {code: this.couponCode, planType: this.billingPeriod, accountType: this.plan.accountType});
          if (promoCodeResponse) {
            this.appliedCode = this.couponCode;
            this.promoCodeSuccessMessage = promoCodeResponse.message;
          }
        } catch {
          this.resetCode();
        }
        this.promoCodeLoad = false;
      }
    },
    resetCode: function() {
      this.appliedCode = '';
      this.couponCode = '';
      this.promoCodeSuccessMessage = '';
    },
    confirmPurchase: async function(cardTokenId) {
      const payload = {
        accountType: this.plan.accountType,
        planType: this.plan.planType === plansTypes.PAYG ? plansTypes.PAYG : this.billingPeriod,
        paymentMode: (this.showCard) ? paymentModes.PREPAID : paymentModes.INVOICING,
      };
      cardTokenId && Object.assign(payload, {cardTokenId});
      this.plan.accountType === accountTypes.TEAM && Object.assign(payload, {seatsToPurchase: this.seats});
      !this.showCard && Object.assign(payload, {email: this.invoicedEmail});
      this.appliedCode !== '' && Object.assign(payload, {promoCode: this.appliedCode});
      const success = await this.PURCHASE_PLAN_REQUEST({payload});
      const routeName = (this.accountType === accountTypes.TEAM && this.userType === userTypes.ADMIN) ?
        'Account' :
        'Get Number';
      success && this.$router.push({name: routeName});
    },
    isCurrentAccountType: function(plan) {
      return (plan.accountType === this.accountType) && this.isUserHasPlanType();
    },
    isCurrentUserPlan: function(plan) {
      return (this.selectedPlan === this.planType) && this.isCurrentAccountType(plan);
    },
  },
};
</script>
<style lang="scss">
.seats {
  width: 80px;
}

.coupon-code-block .apply-button {
  margin-top: 32px;
}

.billing-period-form label {
  display: block;
}

.users-seats-block {
  .btns {
    button {
      margin-right: 0;
      width: 20px;
      height: 20px;
    }

    p {
      width: 60px;
    }
  }
}
.total-price {
  white-space: nowrap;
}
</style>
